import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useSelector } from "react-redux";
import {Trans,  useTranslation } from 'react-i18next';

import { useSearchParams } from "react-router-dom";
import Frame from '../widgets/Frame';

export function RestoreUsername() {
    const [state, setState] = useState({ email: "", error: false });

    const [validToken] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const isLoggedIn = useSelector((state) => (state.value?.isLoggedIn ?? false))

    useEffect(()=>{
        if(isLoggedIn === true && validToken !== null){
            window.location.replace(searchParams.get("redirect"));
        }
    },[isLoggedIn, validToken, searchParams]);

    const handleRegesterClick = (e) => {
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(state.code !== "") {
            setState({
                ...state,
                error: false
            });
        } else {
            setState({
                ...state,
                error: true
            });
            return;
        }
        //navigate("/login");
    };

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
            error: false
        });
    };

    const handleInvalid = (e) => {
        if(e.target.value === ""){
            //e.target.setCustomValidity(t("Please fill in the field"));
        }else{
            //e.target.setCustomValidity("");
        }
    };

    


    return (
            <Frame>
                <div className='main main-restore-username'>
                    <div className='right-main'>
                        <h1>{t("Restore username")}</h1>
                        <h2>{t("d2")}</h2>
                        <div className='center-div mt30'><div className='input'><img src='/images/message-bubbles.png' className='input-icon' alt=''/><input className={state.error?"error":""} required type="text" id="email" placeholder={t("d3")} onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} /></div></div>
                        <div className='center-div'><div className='button button-center button-blue' onClick={(e) => handleSubmit(e)}> {t("d4")} </div></div>
                        <div className='center-div'><h2 className='mt40'><Trans i18nKey="d5" /></h2></div>
                        {/* {state.error?<h2 className='mt40 error'><Trans i18nKey="f1" /></h2>:""}
                        {state.error?<h2><Trans i18nKey="f2" /></h2>:""} */}
                    </div>
                    <div className='left-main'>
                        <img src='/images/person-phone.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </Frame>
    );
}