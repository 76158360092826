import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/user";
import { showErrorDialog } from "../../features/errorDialog";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TITLE, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans, useTranslation } from 'react-i18next';
import cookie from "js-cookie";
import {handleApiErrorMessage, formatErrorMessage} from "../../Helper"
import { useSearchParams } from "react-router-dom";
import Frame from '../widgets/Frame';

export function Login() {
    const [state, setState] = useState({ email: "", password: "", error: false, part2: false });
    const [validToken, setValidToken] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const isLoggedIn = useSelector((state) => (state.value?.isLoggedIn ?? false))
    
    useEffect(() => {
        let errorMessage = cookie.get('logout_error');
        if(errorMessage !== "null" && (typeof errorMessage !== 'undefined')){
            const errorMessageToDisplay = formatErrorMessage(errorMessage, t);
            dispatch(showErrorDialog({title : t(DEFAULT_ERROR_TITLE), text: errorMessageToDisplay}));
            cookie.set('logout_error', "null");
        }
      }, [t, dispatch]);

    useEffect(()=>{
    if(isLoggedIn === true && validToken !== null){
        window.location.replace(searchParams.get("redirect"));
    }
        
    },[isLoggedIn, validToken, searchParams]);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
            error: false
        });
    };

    const handleInvalid = (e) => {
        if(e.target.value === ""){
            //e.target.setCustomValidity(t("Please fill in the field"));
        }else{
            //e.target.setCustomValidity("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(state.password !== "") {
            setState({
                ...state,
                error: false
            });
        } else {
            setState({
                ...state,
                error: true
            });
            return;
        }

        let errorMessage = t(DEFAULT_ERROR_TEXT);

        let config = {};
        let resellerId = cookie.get("reseller_id");
        if(resellerId && (typeof resellerId !== "undefined")){
            config['headers'] = {
                "yc-reseller": resellerId
            }
        }

          
        const data = {userName: state.email, password: state.password};
    
        try {
            const reply = await axios.post(API_URL + '/login', data, config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK
                && reply.data.hasOwnProperty('token')){
                dispatch(login({token : reply.data.token}));
                setValidToken(reply.data.token);

                let redirectFrom = cookie.get('redirect_from');
                if(redirectFrom){
                    window.location.href = redirectFrom+"?token="+reply.data.token;
                } else {
                    navigate("/home");
                }
            }else{
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                  }
                  setState({
                    ...state,
                    error: errorMessage
                });
                  //handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            };
        } catch (e) {
            setState({
                ...state,
                error: errorMessage
            });
            //handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
    };


    const handleNextClick = (e) => {
        e.preventDefault();
        if(state.email !== "") {
            setState({
                ...state,
                part2: true,
                error: false
            });
        } else {
            setState({
                ...state,
                error: true
            });
        }
    };


    const handlePreviousClick = (e) => {
        e.preventDefault();
        setState({
            ...state,
            part2: false,
            error: false
        });
    };


    const toggleShowPassword = (e) => {
        if(e.target.nextElementSibling) {
            e.target.nextElementSibling.type = e.target.nextElementSibling.type === "password"?"text":"password";
        }
    };


    const getFormPart1 = () => {
        return (
            <div className='right-main'>
                <h1>{t("Welcome back to the Yemot Hamessiah")}</h1>
                <h2>{t("b2")}</h2>
                <div className='center-div mt30'><div className='input'><input className={state.error?"error":""} required type="email" id="email" placeholder={t("b3")} value={state.email} onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} /></div></div>
                <div className='center-div'><div className='button button-center button-blue' onClick={(e) => handleNextClick(e)}> {t("next")} </div></div>
                {!state.error?<h2 className='mt20'><Trans i18nKey="b5" /></h2>:""}
                {!state.error?<h2><Trans i18nKey="b6" /></h2>:""}
                {state.error?<h2 className='mt20 error'><Trans i18nKey="c1" /></h2>:""}
                {state.error?<h2><Trans i18nKey="c2" /></h2>:""}
            </div>
        )
    }

    const getFormPart2 = () => {
        return (
            <div className='right-main'>
                <h1>{t("We're almost there...")}</h1>
                <h2>{t("e2")}</h2>
                <h3 className='mt10'><img src='/images/greater-than.png' className='greater-than-icon' onClick={(e) => handlePreviousClick(e)} alt=''/> {state.email}</h3>
                <div className='center-div'><div className='input'><img src='/images/show-password-icon.png' className='input-icon'  onClick={(e) => toggleShowPassword(e)} alt=''/> <input className={state.error?"error":""}  required type="password" id="password" onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} /></div></div>
                <div className='center-div'><div className='button button-center button-blue' onClick={(e) => handleSubmit(e)}> {t("next")} </div></div>
                {!state.error?<h2 className='mt40'><Trans i18nKey="e3" /></h2>:""}
                {state.error?<h2 className='mt40 error'><Trans i18nKey="f1" /></h2>:""}
                {state.error?<h2><Trans i18nKey="f2" /></h2>:""}
            </div>
        )
    }

    return (
        <Frame>
            <div className='main main-start'>
                {state.part2?getFormPart2():getFormPart1()}
                <div className='left-main'>
                    <img src='/images/person-phone.png' className='content-logo' alt=''/>
                </div>
            </div>
        </Frame>
    );
}