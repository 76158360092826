import cookie from "js-cookie";
import queryString from 'query-string';
import { useLocation, useNavigate, Outlet, Navigate } from 'react-router-dom';
import { THEME_SIM_CUSTOMERS } from '../../Constants';

const HanldeURLParameters = ({ children}) => {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const params = queryString.parse(search);
    const redirectFrom = params?.redirect_from;
    const navigate = useNavigate();

    if(redirectFrom){
        const hostRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/:]+)/;
        const match = redirectFrom.match(hostRegex);
        const hostname = match ? match[1] : null;

        if(hostname !== null){
            cookie.set("redirect_from",redirectFrom);
        }
        cookie.set("reseller_id","1");
        if(hostname === process.env.REACT_APP_SIM_CUSTOMERS_HOST){
            cookie.set("theme",THEME_SIM_CUSTOMERS);
            cookie.set("reseller_id","2");
        }
    }

    const invalidToken = params?.invalid_token;
    const currentToken = cookie.get("token");
    if(invalidToken && currentToken && invalidToken === currentToken){
        cookie.remove("token");
    }

    const currentTheme = cookie.get("theme");
    if(currentTheme === THEME_SIM_CUSTOMERS && pathname !== 'login-sim-customers'){
        return <Navigate to="/login-sim-customers" />;
    }else{
        return <Outlet />;
    }
};


export default HanldeURLParameters;