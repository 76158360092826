import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useSelector } from "react-redux";
import {Trans,  useTranslation } from 'react-i18next';

import { useSearchParams } from "react-router-dom";
import Frame from '../widgets/Frame';

export function TwoStepVerification() {

    const [validToken] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const isLoggedIn = useSelector((state) => (state.value?.isLoggedIn ?? false))

    useEffect(()=>{
        if(isLoggedIn === true && validToken !== null){
            window.location.replace(searchParams.get("redirect"));
        }
    },[isLoggedIn, validToken, searchParams]);

    const handleEnableClick = (e) => {
        
    };

    const handleSkipClick = (e) => {
        //navigate("/login");
    };


    return (
            <Frame>
                <div className='main main-reset-password'>
                    <div className='right-main'>
                        <h1>{t("Two-step verification")}</h1>
                        <h2>{t("o2")}</h2>
                        <h2>{t("o3")}</h2>
                        <h2>{t("o4")}</h2>
                        <h2>{t("o5")}</h2>
                        <div className='button-row'>
                            <div className='center-div'><div className='button button-center button-blue button-small ml40' onClick={(e) => handleEnableClick(e)}> {t("o6")} </div></div>
                            <div className='center-div'><div className='button button-center button-green button-small' onClick={(e) => handleSkipClick(e)}> {t("o7")} </div></div>
                        </div>
                        {/* {state.error?<h2 className='mt40 error'><Trans i18nKey="f1" /></h2>:""}
                        {state.error?<h2><Trans i18nKey="f2" /></h2>:""} */}
                    </div>
                    <div className='left-main'>
                        <img src='/images/person-magnifying-glass.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </Frame>
    );
}