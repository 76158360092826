import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useSelector } from "react-redux";
import {Trans,  useTranslation } from 'react-i18next';

import { useSearchParams } from "react-router-dom";
import Frame from '../widgets/Frame';

export function RegisterCurrent() {
    const [state, setState] = useState({idType:"ID number", idNumber:"", phoneCode:"+972", phoneNumber:"", conctactType:"SMS", email: "", errors:{}, showIdDropdown: false, showPhoneCodeDropdown: false });

    const [validToken] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const isLoggedIn = useSelector((state) => (state.value?.isLoggedIn ?? false))

    const phoneCodes = {'+972':'israel', '+1':'usa', '+44':'uk', '+33':'france', '+52':'mexico', '+54':'argentina', '+56':'chile', '+971':'uae', '+47':'norway'}

    useEffect(()=>{
        if(isLoggedIn === true && validToken !== null){
            window.location.replace(searchParams.get("redirect"));
        }
    },[isLoggedIn, validToken, searchParams]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!hasErrors()) {
            //send register ajax
                    
            
            //navigate("/login");
        }

    };

    const handleChange = (e) => {
        delete state.errors[e.target.id];
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {...state.errors},
        });
    };


    const toggleConctactType = (e) => {
        setState({
            ...state,
            conctactType: state.conctactType === 'SMS'?'phone':'SMS',
        });
    };


    const toggleShowIdDropdown = (e) => {
        setState({
            ...state,
            showIdDropdown: !state.showIdDropdown,
        });
    };

    const setIdType = (idType) => {
        setState({
            ...state,
            idType: idType,
            showIdDropdown: false,
        });
    };

    const toggleShowPhoneCodeDropdown = (e) => {
        setState({
            ...state,
            showPhoneCodeDropdown: !state.showPhoneCodeDropdown,
        });
    };

    const setPhoneCode = (phoneCode) => {
        setState({
            ...state,
            phoneCode: phoneCode,
            showPhoneCodeDropdown: false,
        });
    };

    

    const handleInvalid = (e) => {
        e.preventDefault();
        //e.target.setCustomValidity('');
        let id = e.target.id
        state.errors[e.target.id] = true;
        setState({
            ...state,
            errors: {...state.errors},
        });
    };


    const hasErrors = () => {
        return Object.keys(state.errors).length > 0
    }

    


    return (
            <Frame>
                <div className='main main-register'>
                    <div className='right-main'>
                        <form onSubmit={handleSubmit}> 
                            <h1>{t("Create a new user for an existing client")}</h1>
                            <h2>{t("n3")}</h2>
                            <h2><Trans i18nKey="n4" /></h2>
                            <div className='center-div'><div className='input relative'>
                                <img src='/images/id-icon.png' className='input-icon id-icon' alt=''/> 
                                <input className={state.errors.idNumber?"error":""} required type="text" id="idNumber" placeholder={t(state.idType)} onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} />
                                <div className='open-dropdown-icon-div open-id-type-dropdown' onClick={(e) => toggleShowIdDropdown(e)}><img src='/images/open-dropdown-icon.png' className='open-dropdown-icon' alt=''/></div>
                                {state.showIdDropdown?<div className="dropdown id-type-dropdown">
                                    <div className='item' onClick={(e) => setIdType("ID number")}>{t("ID number")}</div>
                                    <div className='item' onClick={(e) => setIdType("Password number")}>{t("Password number")}</div>
                                    <div className='item' onClick={(e) => setIdType("Company number")}>{t("Company number")}</div>
                                </div>:<></>}
                            </div></div>

                            <div className='center-div'><div className='input relative'>
                                <img src='/images/phone-icon.png' className='input-icon' alt=''/>
                                <input className={state.errors.phoneNumber?"error":""} required type="tel" id="phoneNumber" pattern="[\d\s\-]{5,10}" placeholder={t("n7")} onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} />

                                <div className='phone-code ltr'>{state.phoneCode} <img src={'/images/flags/flag-'+phoneCodes[state.phoneCode]+'.png'} className='flag-icon' alt=''/> </div>
                                <div className='open-dropdown-icon-div open-phone-code-dropdown' onClick={(e) => toggleShowPhoneCodeDropdown(e)}><img src='/images/open-dropdown-icon.png' className='open-dropdown-icon' alt=''/></div>
                                {state.showPhoneCodeDropdown?<div className="dropdown phone-code-dropdown">
                                    {Object.entries(phoneCodes).map(([code, name]) => (
                                        <div key={code} className='item' onClick={(e) => setPhoneCode(code)}>
                                            <div>{t(name)}</div>
                                            <div className='phone-code ltr'>{code} <img src={'/images/flags/flag-'+name+'.png'} className='flag-icon' alt=''/></div>
                                        </div>
                                    ))}
                                </div>:<></>}

                            </div></div>



                            

                            <div className='center-div'><div className='contact-type'>
                                <h2>{t("n8")}</h2>
                                <div className='switch' onClick={(e) => toggleConctactType(e)}>
                                    <div className={state.conctactType==='SMS'?'switch-selected':'switch-item'}>SMS</div>
                                    <div className={state.conctactType!=='SMS'?'switch-selected':'switch-item'}>{t("n9")}</div>
                                </div>
                            </div></div>


                            <div className='center-div'><div className='input'><img src='/images/message-bubbles.png' className='input-icon' alt=''/> <input className={state.errors.email?"error":""} required type="email" inputMode="email" id="email" placeholder={t("d3")} onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} /></div></div>
                            <div className='center-div'><button className='button button-center button-blue' type="submit" /*onClick={(e) => handleSubmit(e)}*/> {t("next")} </button></div>

                            <div className='center-div'>{state.errors.email?<h2 className='mt20 error'><Trans i18nKey={"Email is invalid"} /></h2>:""}</div>
                            <div className='center-div'>{state.errors.phoneNumber?<h2 className='mt10 error'><Trans i18nKey={"Phone number is invalid"} /></h2>:""}</div>
                            {/* <h2 className='mt40 error'><Trans i18nKey={"The details were not identified in the system"} /></h2>
                            <h2 className='mt5'><Trans i18nKey="Please try again or contact customer service" /></h2> */}
                        </form>
                    </div>

                    <div className='left-main'>
                        <img src='/images/person-pointing.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </Frame>
    );
}