import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {useSelector } from "react-redux";
import {Trans,  useTranslation } from 'react-i18next';

import { useSearchParams } from "react-router-dom";
import Frame from '../widgets/Frame';

export function CreatePassword() {
    const [state, setState] = useState({ password: "", password2: "", errors: {} });

    const [validToken] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const isLoggedIn = useSelector((state) => (state.value?.isLoggedIn ?? false))

    useEffect(()=>{
        if(isLoggedIn === true && validToken !== null){
            window.location.replace(searchParams.get("redirect"));
        }
    },[isLoggedIn, validToken, searchParams]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!hasErrors()) {
            //send  ajax
                    
            
            //navigate("/login");
        }

    };

    const handleChange = (e) => {
        delete state.errors[e.target.id];
        if((e.target.id === 'password' && e.target.value !== state.password2) || (e.target.id === 'password2' && e.target.value !== state.password)) {
            state.errors['password2'] = true;
        } else {
            delete state.errors['password'];
            delete state.errors['password2'];
        }
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {...state.errors},
        });
    };

    const handleInvalid = (e) => {
        e.preventDefault();
        let id = e.target.id
        state.errors[e.target.id] = true;
        setState({
            ...state,
            errors: {...state.errors},
        });
    };


    const hasErrors = () => {
        return Object.keys(state.errors).length > 0
    }


    return (
            <Frame>
                <div className='main main-create-password'>
                    <div className='right-main'>
                        <form onSubmit={handleSubmit}> 
                            <h1>{t("Create a new password")}</h1>
                            <h2>{t("h2")}</h2>
                            <h2 className='list-item'>{t("h3")}</h2>
                            <h2 className='list-item'>{t("h4")}</h2>
                            <h2 className='list-item'>{t("h5")}</h2>
                            <h2 className='list-item'>{t("h6")}</h2>
                            <div className='center-div mt30'><div className='input'><input className={state.errors.password?"error":""} required type="text" id="password" placeholder={t("h7")} onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} /></div></div>
                            <div className='center-div'><div className='input'><input className={state.errors.password2?"error":""} required type="text" id="password2" placeholder={t("h8")} onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} /></div></div>
                            <div className='center-div'><button className='button button-center button-blue' type='submit'> {t("h9")} </button></div>

                            <div className='center-div'>{state.errors.password?<h2 className='mt10 error'><Trans i18nKey={"The password you selected does not meet our security requirements"} /></h2>:""}</div>
                            <div className='center-div'>{state.errors.password2?<h2 className='mt10 error'><Trans i18nKey={"Passwords do not match"} /></h2>:""}</div>
                        </form>
                    </div>
                    <div className='left-main'>
                        <img src='/images/person-phone.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </Frame>
    );
}