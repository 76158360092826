import React from 'react';
import Header from "./Header";

const getChildren = (children) => {
    return children;
}

const Frame = ({ children }) => {
    return <div className='frame'>
        <Header></Header>
        <div className="content">
            {getChildren(children)}
        </div>
     </div>;
};


export default Frame;