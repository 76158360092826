import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showErrorDialog } from "../../features/errorDialog";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TITLE, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import { Button, Typography, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import cookie from "js-cookie";
import {handleApiErrorMessage, formatErrorMessage} from "../../Helper"
import { logout } from "../../features/user";


export function LoginSimCustomers() {
    const [state, setState] = useState({ username: "", password: "" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    useEffect(() => {
        document.body.style.background = "white";
        let errorMessage = cookie.get('logout_error');
        if(errorMessage !== "null" && (typeof errorMessage !== 'undefined')){
            const errorMessageToDisplay = formatErrorMessage(errorMessage, t);
            dispatch(showErrorDialog({title : t(DEFAULT_ERROR_TITLE), text: errorMessageToDisplay}));
            cookie.set('logout_error', "null");
        }
      }, []);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        });
    };

    const handleInvalid = (e) => {
        if(e.target.value === ""){
            e.target.setCustomValidity(t("Please fill in the field"));
        }else{
            e.target.setCustomValidity("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let errorMessage = t(DEFAULT_ERROR_TEXT);

        let config = {};
        let resellerId = cookie.get("reseller_id");
        if(resellerId && (typeof resellerId !== "undefined")){
            config['headers'] = {
                "yc-reseller": resellerId
            }
        }

        const data = {userName: state.username, password: state.password};
    
        try {
            const reply = await axios.post(API_URL + '/login', data, config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK
                && reply.data.hasOwnProperty('token')){
                let redirectFrom = cookie.get('redirect_from');
                if(redirectFrom){
                    window.location.href = redirectFrom+"?token="+reply.data.token;
                }
            }else{
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                  }
                  handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
            };
        } catch (e) {
            handleApiErrorMessage(errorMessage, t, dispatch, showErrorDialog, logout, navigate);
        }
    };

    return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={[
                    {
                      mt: 2
                  }
                ]}
                >
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Typography variant="h5" sx={{mb: 1}}>{t('Login')}</Typography>
                    <Box sx={{mb: 1}}>
                        <Typography>{t('Username')}</Typography>
                        <TextField  size="small" required type="username" id="username" onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} />
                    </Box>
                    <Box sx={{mb: 1}}>
                        <Typography>{t('Password')}</Typography>
                        <TextField  size="small" required type="password" id="password" onChange={(e) => handleChange(e)} onInvalid={(e) => handleInvalid(e)} />
                    </Box>
                    <Button type="submit" variant="contained">{t('Login')}</Button>
                </form>
            </Box>
    );
}