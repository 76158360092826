import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cookie from "js-cookie";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import Frame from '../widgets/Frame';
import axios from "axios";
import { API_OK, API_URL} from '../../Constants';
import { logout } from '../../features/user';

export function Start() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => (state.value?.isLoggedIn ?? false))
    
    useEffect(() => {
        async function checkValidToken() {
            const token = cookie.get('token');
        if(token){
            const redirectBackUrl = cookie.get('redirect_from');
            try {
                const reply = await axios.post(API_URL + '/isTokenValid', {token: token, url: (redirectBackUrl ?? '/')});
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK
                    && reply.data.hasOwnProperty('token')){
                        if(reply.data.isTokenValid !== true){
                            dispatch(logout());
                        }else{
                            let redirectFrom = cookie.get('redirect_from');
                            if(redirectFrom){
                                window.setTimeout(function(){
                                    window.location.href = redirectFrom+"?token="+reply.data.token;
                                  }, 3000);
                            }
                        }
                }else{
                    dispatch(logout());
                };
            } catch (e) {
                dispatch(logout());
            }
        }
        }
        checkValidToken();
      }, []);



    const handleRegesterClick = (e) => {
        
    };

    const handleLoginClick = (e) => {
        navigate("/login");
    }; 

    


    return (
            <Frame>
                <div className='main main-start'>
                    <div className='right-main'>
                        <h1>{t("a1")}</h1>
                        <h2>{t("a2")}</h2>
                        <h2>{t("a3")}</h2> 
                        <div className='center-div mt30'><div className='button' onClick={(e) => handleRegesterClick(e)}><img src='/images/register-icon.png' className='button-icon' alt=''/> {t("a4")} </div></div>
                        <div className='center-div'><div className='button' onClick={(e) => handleLoginClick(e)}><img src='/images/login-icon.png' className='button-icon' alt=''/> {t("a5")} </div></div>
                    </div>
                    <div className='left-main'>
                        <img src='/images/person-phone.png' className='content-logo' alt=''/>
                    </div>
                </div>
            </Frame>
    );
}