import React from 'react';

const Header = () => {
    return <div className="header">
        <img src='/images/header-logo.png' className='header-logo' alt=''/>
        <div className='header-icons'>
            <img src='/images/notification-icon.png' className='notification-icon' alt=''/>
            <img src='/images/user-icon.png' className='user-icon' alt=''/>
        </div>
        
    </div>;
};


export default Header;